module UserService {
    let app = angular.module("app");

    app.factory("UserService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            login: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/login-local-password', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }),

            renewToken: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/renew-token', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }),
            
            getJWTToken: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-full-jwt-token', {
            }, {
                query: {
                        method: 'GET',
                        isArray: false
                }
            }),

            isSsoEnabledForLoggedUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/is-sso-enabled-for-logged-user', {
            }, {
                get: {
                    method: 'GET',
                    isArray: false
                }
            }),

            langs: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-all-langs', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }),

            getUserAcknowledges: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-all-userAcknowledges', {
            }, {
                get: {
                    method: 'GET',
                    isArray: false
                }
            }),

            setAcknowledgesApplicationTour: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/set-acknowledges-applicationTour', {
            }, {
                set: {
                    method: 'POST',
                    isArray: false
                }
            }),

            logout: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/logout', {
            }, {
                get: {
                    method: 'GET',
                    isArray: false
                }
            }),

            getGroupsOfUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-groups-of-user', {
            }, {
                get: {
                     method: 'GET',
                     isArray: false
                }
            }),

            getTokenFromSSOKey: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/retrieve-token-after-login', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            },
            ),

            // Metodi per la gestione dei diritti e autenticazione
            isUserAuthorized: (requiredAuthId: string) => {
                // Cerco se nella lista delle mie autorizzazioni ho il trittico richiesto
                var ok = false;
                var authObj = $.grep(GlobalApplicationData.auths, (e) => {
                    return e === requiredAuthId;
                });
                ok = authObj.length >= 1;
                return ok;
            }

        };
        return serv;
    }]);
}